import React, { useRef } from 'react';
import Terminal from 'react-console-emulator';

export const App = () => {
  const terminalRef = useRef(null);

  const commands = {
    echo: {
      description: 'Echo a passed string.',
      usage: 'echo <string>',
      fn: (...args) => args.join(' '),
    },
    ls: {
      description: 'List files.',

      fn: (arg) => {
        const files = 'file1 file2 file3';
        if (arg === '-al') {
          return files + ' flag.txt';
        }

        return files;
      },
    },
    pwd: {
      description: 'Print working directory.',
      fn: () => '/home/user',
    },
    sudo: {
      description: 'Super user do.',
      fn: () => 'Good thinking. Permission denied. You are overthinking it.',
    },

    cat: {
      description: 'Print file contents.',
      fn: (filename) => {
        if (filename === 'flag.txt') {
          return 'Y29uZ3JhdHMgeW91J3JlIHBhc3QgdGhlIGZpcnN0IGdhdGUsIHR5cGUgcG93ZXJvdmVyd2hlbG1pbmcgaW50byB0aGUgdGVybWluYWwgdG8gY29udGludWU=';
        } else {
          return 'Permission denied.';
        }
      },
    },
    poweroverwhelming: {
      description: 'nice work',
      fn: () => {
        // animate all of the ref's divs slowly rotating and moving outward
        // then fade out the terminal
        // then show the flag
        console.log(terminalRef.current);
      },
    },
  };
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        backgroundColor: '#0d0d0d',
      }}
    >
      <Terminal
        ref={terminalRef}
        noHistory
        style={{
          wordWrap: 'break-word',
          width: '100%',
          maxWidth: '800px',
          height: '100%',
          maxHeight: '600px',
          backgroundColor: '#000',
          color: '#fff',
          borderRadius: '0',
        }}
        commands={commands}
        welcomeMessage={`
        Welcome to my generic placeholder for frozen projects.
        Can you capture the flag?
        
        ...a strange game...

        ...the only winning move is not to play...
        `}
        promptLabel={'anon@jbox:~$'}
      />
    </div>
  );
};
